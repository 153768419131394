import React from "react"
import Layout from "../../components/global/Layout"

const Om = () => {
  return (
    <Layout
      metaTitle="Kom i kontakt"
      metaDescription="Hvad end du har spørgsmål, ønsker at annoncere på Opholdsguide eller noget helt andet, skal du selvfølgelig være mere end velkommen til at kontakte os."
      slug="/information/om/"
    >
      <div className="container my-xl">
        <section className="text-content">
          <h1>Om Opholdsguide.dk</h1>
          <p>
            Hos Opholdsguide sammenligner vi kroophold, slotsophold, spaophold
            og andre weekendophold i Danmark med hinanden. Vi ønsker at være
            Danmarks foretrukne hjemmeside, hvor man kan læse detaljeret om de
            ophold, der bliver udbudt.
          </p>
          <p>
            Vi vil gerne hjælpe dig med at finde de bedste ophold, uanset om det
            er til en bryllupsgave, en romantisk tur med kæresten, en god
            veninde eller noget helt fjerde.
          </p>
          <h2>Bliv klogere på os</h2>
          <p>
            Opholdsguide er stiftet af Magnus. Jeg elsker selv at tage på ophold
            i ind- og udland. Det er denne passion, som jeg gerne vil dele her
            på hjemmesiden. Mit mål er at inspirere og guide dig til at finde
            det rette ophold for dig - uanset hvad formålet er.
          </p>
          <p>Jeg håber, at du kan få noget ud af mine guides.</p>
          <h2>Ansigteterne bag</h2>
          <h3>Magnus, Forfatter</h3>
          <p>
            Mit navn er Magnus, og jeg er forfatter på Opholdsguide. Jeg er vild
            med at tage på ophold med min kæreste og familie, og de oplevelser
            og erfaringer vil jeg gerne dele med jer. Jeg ønsker dig en rigtig
            god tur!
          </p>
          <p>
            Kontakt mig på{" "}
            <a href="mailto:magnus@opholdsguide.dk">magnus@opholdsguide.dk</a>
          </p>
          <h3>Josefine, Hotel relations</h3>
          <p>
            Mit navn er Josefine, og jeg står for at opdatere priser, opretholde
            relationer med vores samarbejdspartnere og så videre.
          </p>
          <p>
            Kontakt mig på{" "}
            <a href="mailto:josefine@opholdsguide.dk">
              josefine@opholdsguide.dk
            </a>
          </p>
        </section>
      </div>
    </Layout>
  )
}

export default Om
